import { useState } from 'react'
import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../../shared/use-history'
import { useLocalState } from '../../../shared/local-state'
import { CreateInventoryForm } from './CreateInventoryForm'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { ValidationIssues } from '../../../mprise-light/ValidationIssues'
import { useAppSettingsContext } from '../../../context/AppSettingsContext'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { fail } from '../../../shared/typescript'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { WORKTASK_BY_ID_WITH_TASK_RESULTS } from '../../../gql/query/workItems/workTaskByIdWithTaskResults'
import { STOP_TASK } from '../../../gql/mutation/statusChange/statusChange'
import { REPORT_ITEM_COMSUMPTION } from '../../../gql/mutation/reportItemConsumption'
import { REPORT_JOB_INVENTORY_PUTAWAY } from '../../../gql/mutation/reportJobInventoryPutAway'
import { parseFloatQuantity } from '../../../shared/formats'
import { Maybe, WorkItemTemplateTaskOption } from '../../../shared/enums'
import { STOP_TIME_REG } from '../../../gql/mutation/timeReg'
import { FormikContextType } from 'formik'
import { i18n } from '../../../i18n/instance'

export interface CreateInventoryFormDefinition {
  reportedItemConsumptions: Array<{
    id: number
    itemId: string
    itemName: string
    plannedVariantCode: string
    quantity?: Maybe<number>
    quantityUnit?: Maybe<string>
    plannedQuantity: number
    reportedQuantity: number
    lotNumbersRequired: Maybe<boolean>
    activeLot: Maybe<{ lotId: string; lotNumber: string }>
    scannedLots: Maybe<Array<ScannedLot>>
    plannedLotNumbers: Array<{ lotNumber: string; quantity: number }>
    reportedLotNumbers: Array<{ lotNumber: string; quantity: number }>
  }>
  quantityPutAway: Maybe<number>
  plannedQuantityPutAway: number | undefined
  reportedQuantityPutAway: number | undefined
  quantityUnitPutAway: Maybe<string> | undefined
  toPositionPutAway: Maybe<{ id: string; name: string; code: Maybe<string> }>
  taskOptions: Array<WorkItemTemplateTaskOption>
}

export interface ScannedLot {
  id: string
  lotNumber: string
  quantity: number
}

export const CreateInventoryRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()
  const apollo = useApolloClient()
  const cache = apollo.cache

  /** Flag to signify the "Are you sure you want to leave?" prompt has already been sent. Without this, it is sent twice in some situations. */
  const [blockAlreadyHandled, setBlockAlreadyHandled] = useState<boolean>(false)

  const { resource, numberFormat } = useAppSettingsContext()
  const resourceId = resource?.id ?? h.push('/')

  const workItemId = useParams().workItemId!
  const taskId = useParams().taskId!
  const [stopTaskMutation, stopTaskState] = useMutation(STOP_TASK)
  const [stopTimeReg] = useMutation(STOP_TIME_REG)

  const taskQuery = useQuery(WORKTASK_BY_ID_WITH_TASK_RESULTS, {
    variables: {
      where: [{ field: 'id', options: { eq: +taskId } }],
      filter: { mandatoryType: 'JOB_WORK_ORDER' },
    },
  })

  const task = taskQuery.data?.nworkTask
  const jobInventoryPutAway = task?.workItem?.jobInventoryPutAway ? task.workItem.jobInventoryPutAway[0] : null
  const plannedPosition = jobInventoryPutAway?.planned.position ?? null
  const workItemCosmosKey = task?.workItem.cosmosKey
  const taskCosmosKey = task?.cosmosKey

  const handleClose = async (e: React.FormEvent<Element>, fc: FormikContextType<CreateInventoryFormDefinition>) => {
    if (fc.dirty && !fc.isSubmitting) {
      if (!window.confirm(i18n.t('CONFIRM_DISCARD_UNSAVED_CHANGES'))) {
        return
      }
      setBlockAlreadyHandled(true)
    }

    if (workItemId && taskId) {
      await stopTaskMutation({
        variables: {
          workItemId: +workItemId,
          taskId: +taskId,
          currentResourceId: +resourceId,
        },
        refetchQueries: [WORKTASK_BY_ID_WITH_TASK_RESULTS],
      })
      if (workItemCosmosKey && taskCosmosKey) {
        await stopTimeReg({
          variables: {
            workItemId: workItemCosmosKey,
            taskId: taskCosmosKey,
          },
        })
      }
    }
    h.goBack()
    // Timeout needed to make sure it runs after the navigation has been completed.
    setTimeout(() => setBlockAlreadyHandled(false), 0)
  }

  const [reportItemConsumption, reportItemConsumptionState] = useMutation(REPORT_ITEM_COMSUMPTION)
  const [reportJobInventoryPutAway, reportJobInventoryPutAwayState] = useMutation(REPORT_JOB_INVENTORY_PUTAWAY)

  const [initialValues] = useLocalState((): CreateInventoryFormDefinition => {
    const reportedQuantityPutAway = jobInventoryPutAway?.reported.reduce(
      (a: any, b: { quantity: any }) => a + (b.quantity ?? 0),
      0,
    )
    const plannedQuantityPutAway = jobInventoryPutAway?.planned.quantity
    return withFormikCompareFix({
      toPositionPutAway: plannedPosition
        ? { id: plannedPosition!.id!, name: plannedPosition!.name!, code: plannedPosition!.code! }
        : { id: '', name: '', code: '' },
      quantityPutAway: plannedQuantityPutAway - reportedQuantityPutAway,
      plannedQuantityPutAway: plannedQuantityPutAway,
      reportedQuantityPutAway: reportedQuantityPutAway,
      quantityUnitPutAway: jobInventoryPutAway?.planned.quantityUnit,
      taskOptions: task?.taskOptions ?? [],
      reportedItemConsumptions:
        task?.workItem.itemConsumption?.map((i: any) => ({
          id: +i.id,
          itemId: i.planned.item?.id!,
          itemName: i.planned.itemDescription,
          plannedVariantCode: i.planned.variantCode ?? '',
          quantity: null,
          plannedQuantity: i.planned.quantity,
          reportedQuantity: i.reported.reduce((a: any, b: any) => a + (b.quantity ?? 0), 0),
          quantityUnit: i.planned.quantityUnit,
          lotNumbersRequired: i.planned.lotNumbersRequired,
          plannedLotNumbers: i.planned.lotNumbers ?? [],
          lotNumber: null,
          reportedLotNumbers: i.reported.flatMap((x: any) => x.lotNumbers) ?? [],
        })) ?? [],
    })
  }, [task])

  const handleSubmit = async (
    form: CreateInventoryFormDefinition,
    actions: Formik.FormikHelpers<CreateInventoryFormDefinition>,
  ) => {
    actions.resetForm()

    if (!resourceId) {
      fail('expects resource id')
    }

    let success = true
    let autoFinished = false

    if (jobInventoryPutAway && form.quantityPutAway) {
      try {
        const quantity = parseFloatQuantity(form.quantityPutAway, numberFormat)
        await reportJobInventoryPutAway({
          variables: {
            workItemId: workItemId!,
            taskId: taskId!,
            taskPutAwayId: +jobInventoryPutAway.id!,
            values: {
              quantity: roundToNearestHalf(quantity),
              quantityUnit: jobInventoryPutAway.planned.quantityUnit,
              quantityPerArea: jobInventoryPutAway.planned.quantityPerArea,
              quantityPerAreaUnit: jobInventoryPutAway.planned.quantityPerAreaUnit,
              resourceId: resourceId,
              positionId: form.toPositionPutAway!.id,
            },
          },
        }).then(response => {
          autoFinished = response.data.registerJobInventoryPutAway.autoFinished
          if (autoFinished) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })
          }
          success &&= !!response.data
        })
      } catch (e) {
        success = false
      }
    }

    if (success) {
      //TODO: Doing a submit for every ItemConsumption separately is not the most efficient. Ideally the api should accept multiple at once.
      for (const itemConsumption of form.reportedItemConsumptions) {
        if (itemConsumption.scannedLots?.length || itemConsumption.quantity) {
          const totalQuantity = itemConsumption.scannedLots?.length
            ? itemConsumption.scannedLots.reduce((a, b) => a + b.quantity, 0)
            : parseFloatQuantity(itemConsumption.quantity, numberFormat)

          const lots = itemConsumption.scannedLots?.length
            ? itemConsumption.scannedLots.map(s => ({ lotId: s.id, lotNumber: s.lotNumber, quantity: s.quantity }))
            : itemConsumption.activeLot
              ? [{ ...itemConsumption.activeLot, quantity: totalQuantity }]
              : []

          const result = await reportItemConsumption({
            variables: {
              workItemId: workItemId,
              taskId: taskId,
              itemConsumptionId: itemConsumption.id,
              values: {
                itemId: itemConsumption.itemId,
                lotNumbers: lots,
                quantity: totalQuantity,
                quantityUnit: itemConsumption.quantityUnit,
                resourceId: resourceId,
              },
            },
          })
          success &&= !!result.data
        }
      }
    }

    if (success) {
      MAudio.scanSuccess()
      alerts.push(t('SUCCESS_MESSAGE'), `success`)
    } else {
      MAudio.scanError()
      alerts.push(t('TITLE_MUTATION_ERROR'), 'error')
    }

    if (workItemId && taskId) {
      if (!autoFinished) {
        await stopTaskMutation({
          variables: {
            workItemId: +workItemId,
            taskId: +taskId,
            currentResourceId: +resourceId,
          },
        })
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'nworkItem' })
      }
      if (workItemCosmosKey && taskCosmosKey) {
        await stopTimeReg({
          variables: {
            workItemId: workItemCosmosKey,
            taskId: taskCosmosKey,
          },
        })
      }
    }
    h.goBack()
  }

  return (
    <>
      <Helmet title={t('EXECUTE_TASK')} />
      <Formik.Formik
        enableReinitialize
        initialValues={initialValues}
        validate={values => CreateInventoryForm.validate(values, t)}
        onSubmit={handleSubmit}
      >
        <DialogFormik
          minWidth='xl'
          title={t('EXECUTE_TASK')}
          onCloseWithContext={handleClose}
          onClose={() => {}}
          open={true}
        >
          <SavingSwitchPanel mutation={[reportItemConsumptionState, reportJobInventoryPutAwayState, stopTaskState]}>
            <ValidationIssues />
            <MutationErrorMessage
              mutation={[reportItemConsumptionState, reportJobInventoryPutAwayState, stopTaskState]}
            />
            <CreateInventoryForm blockAlreadyHandled={blockAlreadyHandled} />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}

function roundToNearestHalf(n: number) {
  return Math.round(n * 2) / 2
}
