import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, InputProps } from '@mui/material'
import { useMField } from '@mprise/react-ui/dist/controls/mfield/context'
import { NumberFormatItem, useAppSettingsContext } from '../context/AppSettingsContext'
import { FieldInteger, MinusButton, PlusButton } from './FieldInteger'

/** Amount of digits supported after the decimal separator. */
const DECIMAL_FRACTION_DIGITS = 1

// Copied and adjusted from react-ui.
export const FieldDecimal = (props: InputProps & { step?: number; max?: number }) => {
  const { t } = useTranslation()
  const f = useMField()

  const { numberFormat } = useAppSettingsContext()

  const max = props.max
  const step = props.step ?? 1

  const [focused, setFocused] = useState(false)
  const [plusDisabled, setPlusDisabled] = useState(max === 0)

  const inputRef = useRef<HTMLInputElement>(null)

  const [rawInputValue, setRawInputValue] = useState<string>(formatNumber(f.value, numberFormat))

  /** Updates both the internal field value (must be a valid JS number) and the display value (which is a string). */
  const handleChangeInternal = (rawValue: string) => {
    let displayValue = rawValue === '0' ? '' : rawValue
    if (displayValue && numberFormat.id === 'DECIMAL_COMMA') {
      displayValue = displayValue.toString().replace('.', ',')
    }
    setRawInputValue(displayValue)

    const newNumericValue = rawValue ? parseFloat(rawValue === '0' ? '' : rawValue) : 0
    f.onChange?.(isNaN(newNumericValue) ? 0 : newNumericValue)
  }

  useEffect(() => {
    const newFieldValue = f.value
    const currentRawInputValue = parseFloat(rawInputValue?.replace(',', '.'))
    const currentInputNumericValue = isNaN(currentRawInputValue) ? 0 : currentRawInputValue
    if (newFieldValue !== currentInputNumericValue) {
      const updatedDisplayValue = formatNumber(newFieldValue, numberFormat)
      setRawInputValue(updatedDisplayValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: Only needs to run when f.value changes from outside the component, not more often.
  }, [f.value])

  const handleMinus = () => {
    const currentValue = f.value ? parseFloat(f.value) : 0
    const newValue = Math.max(0, currentValue - step)
    handleChangeInternal(newValue.toFixed(DECIMAL_FRACTION_DIGITS))

    if (max && newValue < max && plusDisabled) {
      setPlusDisabled(false)
    }
  }

  const handlePlus = () => {
    const currentValue = f.value ? parseFloat(f.value) : 0
    const newValue = currentValue + step
    if (max == null || newValue <= max) {
      handleChangeInternal(newValue.toFixed(DECIMAL_FRACTION_DIGITS))
    } else if (!plusDisabled) {
      setPlusDisabled(true)
    }
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (!/^[0-9,.]*$/.test(e.target.value)) {
      return
    }
    const str = e.target.value ?? ''
    const firstPunctuationIndex = getFirstPunctuationIndex(str)
    if (firstPunctuationIndex > -1) {
      const beforeDecimal = str.slice(0, firstPunctuationIndex)
      const afterDecimal = str.slice(firstPunctuationIndex).replace(/[,.]/g, '')
      handleChangeInternal(`${beforeDecimal}.${afterDecimal}`)
    } else {
      handleChangeInternal(e.target.value ?? '')
    }
  }

  const handleFocus = () => {
    setTimeout(() => {
      inputRef.current?.setSelectionRange(-1, -1)
    })
    setFocused(true)
  }
  const handleBlur = () => setFocused(false)

  return (
    <FieldInteger.Container focused={focused} style={{ width: '161px' }}>
      <MinusButton handleMinus={handleMinus} />
      <Input
        name={f?.name ?? ''}
        id={f?.id ?? ''}
        value={rawInputValue}
        type='text'
        inputMode='numeric'
        title={t('INVALID_FORMAT_QUANTITY', { NUMBER: numberFormat.id === 'DECIMAL_COMMA' ? '1,5' : '1.5' })}
        onChange={handleChange}
        disableUnderline
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputProps={{
          pattern:
            numberFormat.id === 'DECIMAL_COMMA'
              ? '^(?!.*\\.\\d{1,2}$)[0-9.]+(?:,[0-9]+)?$'
              : '^(?!.*,\\d{1,2}$)[0-9,]+(?:\\.[0-9]+)?$',
          style: { textAlign: 'center' },
          inputMode: 'numeric',
        }}
        inputRef={inputRef}
        {...props}
      />
      <PlusButton handlePlus={handlePlus} disabled={plusDisabled} />
    </FieldInteger.Container>
  )
}

function getFirstPunctuationIndex(str: string) {
  for (let i = 0; str[i]; i++) {
    if (str[i] === ',' || str[i] === '.') {
      return i
    }
  }
  return -1
}

function formatNumber(quantity: number, numberFormat: NumberFormatItem) {
  if (numberFormat.id === 'DECIMAL_COMMA') {
    return quantity?.toString()?.replace('.', ',')
  }
  return quantity?.toString()
}
