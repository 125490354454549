import { useTranslation } from 'react-i18next'
import { Section } from '../../components/Section'
import { JobInventoryDetail } from '../../shared/interfaces'
import { formatQuantity, formatUnitOfMeasure } from '../../shared/formats'
import { NumberFormatItem, useAppSettingsContext } from '../../context/AppSettingsContext'

export const SelectedJobInventoryDetailsSection = ({
  jobInventoryDetails,
}: {
  jobInventoryDetails: Array<JobInventoryDetail>
}) => {
  const { t } = useTranslation()

  const { numberFormat } = useAppSettingsContext()

  if (!jobInventoryDetails?.length) {
    return <></>
  }

  // When used with multiple inventories selected at the same time, all values except sorting should be the same amongst them.
  const item = jobInventoryDetails[0]?.job?.item?.name ?? ''
  const fromPosition = jobInventoryDetails[0]?.position?.code ?? ''
  const phase = jobInventoryDetails[0]?.productionPhase?.productionPhase ?? ''

  const isSingle = jobInventoryDetails.length === 1

  return (
    <Section>
      <div className='gh-form-task-card' style={{ marginLeft: '0.25rem' }}>
        <div className='gh-form-task-header' style={{ justifyContent: 'start' }}>
          {t('SELECTED_INVENTORY')}
        </div>
        <div className='gh-taskItem-card-content'>
          <div>{`${t('ITEM')}: ${item}`}</div>
          <div>{`${t('POSITION')}: ${fromPosition}`}</div>
          <div>{`${t('PHASE')}: ${phase}`}</div>
          {isSingle && !jobInventoryDetails[0]?.sortingCode ? (
            <div>{`${t('QUANTITY')}: ${getRemainingQuantityText(jobInventoryDetails[0], numberFormat)}`}</div>
          ) : (
            <div>{`${isSingle ? t('SORTING') : t('SORTINGS')}: ${jobInventoryDetails.map(jobInventoryDetail => {
              return ` ${jobInventoryDetail?.sortingCode} (${getRemainingQuantityText(
                jobInventoryDetail,
                numberFormat,
              )})`
            })}`}</div>
          )}
        </div>
      </div>
    </Section>
  )
}

const getRemainingQuantityText = (jobInventoryDetail?: JobInventoryDetail, numberFormat?: NumberFormatItem) => {
  const remainingQuantity = jobInventoryDetail?.remainingQuantity ?? 0
  const remQtyPerUnitOfMeasure = jobInventoryDetail?.remQtyPerUnitOfMeasure ?? 0
  const unitOfMeasure = formatUnitOfMeasure(jobInventoryDetail?.unitOfMeasure)

  return remainingQuantity > 0
    ? `${formatQuantity(remainingQuantity, numberFormat!)} ${unitOfMeasure}` +
        (remQtyPerUnitOfMeasure === 0 || remQtyPerUnitOfMeasure > 1 ? ` | ${remQtyPerUnitOfMeasure} per UOM` : '')
    : ''
}
